import React from 'react'
import {
    Technologies,
    Tech,
    TechImg,
    TechName,
    ContactWrapper,
} from "../About/AboutElements";
import { webSkills, apiSkills, databaseSkills, programmingSkills, otherSkills } from './skillsData';

const Skills = () => {
    return (
        <ContactWrapper id="skills">
            <div className="Container">

                <div className="SectionTitle">My Skills</div>
                <div className="BigCard">
                    <h2>Web Technologies/Frameworks</h2>
                    <div className="AboutBio">
                        <Technologies>
                            {webSkills.map((stack, index) => (
                                <Tech key={index} className="tech">
                                    <img 
                                        className="techImg" src={stack.img} alt={stack.name} />
                                    <TechName>{stack.name}</TechName>
                                </Tech>
                            ))}
                        </Technologies>
                    </div>


                    <h2>Api's</h2>
                    <div className="AboutBio">
                        <Technologies>
                            {apiSkills.map((stack, index) => (
                                <Tech key={index} className="tech">
                                    <TechImg src={stack.img} alt={stack.name} />
                                    <TechName>{stack.name}</TechName>
                                </Tech>
                            ))}
                        </Technologies>
                    </div>


                    <h2>Database</h2>
                    <div className="AboutBio">
                        <Technologies>
                            {databaseSkills.map((stack, index) => (
                                <Tech key={index} className="tech">
                                    <TechImg src={stack.img} alt={stack.name} />
                                    <TechName>{stack.name}</TechName>
                                </Tech>
                            ))}
                        </Technologies>
                    </div>

                    <h2>Programming Languages</h2>
                    <div className="AboutBio">
                        <Technologies>
                            {programmingSkills.map((stack, index) => (
                                <Tech key={index} className="tech">
                                    <TechImg src={stack.img} alt={stack.name} />
                                    <TechName>{stack.name}</TechName>
                                </Tech>
                            ))}
                        </Technologies>
                    </div>


                    <h2>Other Skills</h2>
                    <div className="AboutBio">
                        <Technologies>
                            {otherSkills.map((stack, index) => (
                                <Tech key={index} className="tech">
                                    <TechImg src={stack.img} alt={stack.name} />
                                    <TechName>{stack.name}</TechName>
                                </Tech>
                            ))}
                        </Technologies>
                    </div>

                </div>
            </div>
        </ContactWrapper>
    )
}

export default Skills