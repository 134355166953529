import React from "react";
import { Nav, Logo, NavLink, Bars, NavMenu } from "./HeaderElements";
import { motion } from "framer-motion";

const Header = ({ toggle }) => {
  return (
    <div className="Container" style={{ padding: 0 }}>
      <Nav>
        <Logo to="/" style={{fontFamily: "Agustina Regular"}}>
          <img style={{height: "150%"}} src="/k4logo.png" alt="Karan Chawla" />
        </Logo>
        <NavMenu>
          <NavLink className="menu-item" to="about">
            About Me
          </NavLink>
          <NavLink className="menu-item" to="experience">
            Experience
          </NavLink>
          <NavLink className="menu-item" to="skills">
            Skills
          </NavLink>
          <NavLink className="menu-item" to="contact">
            Contact Me
          </NavLink>
        </NavMenu>
        <motion.div className="navButton"
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 0.8 }}

        >
          <a
            className="btn PrimaryBtn"
            href="/resume/Karan-Resume-Basic.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Resume
          </a>
        </motion.div>
        <Bars onClick={toggle} />
      </Nav>
    </div>
  );
};

export default Header;
