import styled from "@emotion/styled";

export const ContactWrapper = styled.div`
  
`;

export const Image = styled.img`
  max-width: 120px;
  margin: 0 auto;
  margin-bottom: 1rem;
`;

export const Technologies = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: -2rem;
`;

export const Tech = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100px;
  min-width: 100px;
  margin-bottom: 2rem;

`;

export const TechImg = styled.img`
  height: 50px;
  width: auto;
  padding-bottom: 5px;
`;

export const TechName = styled.div`
  font-size: 14px;
`;

export const AboutContainer = styled.div`
  padding-bottom: 1rem;
  padding-top: 2rem;
  padding-right: 0.3rem;
  padding-left: 0.3rem;
  margin-right: 0;
  margin-left: 0;
  display: flex;
  justify-content: space-between;


  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1000px;
  }
`;

export const AboutWrapper = styled.div`
  display: flex;
  justify-content: space-between;


  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
`;

export const AboutRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  margin: 30px;
  flex: 1;

  h1 {
    font-size: 2.8rem;
    color: #f6f6f6;
    opacity: 0.98;
    font-weight: 400;
  }

  h5 {
    font-size: 1.6rem;
    color: #f6f6f6;
    margin-bottom: 1rem;
    font-weight: 400;
  }

  p {
    font-size: 17px;
    color: #f6f6f6;
    opacity: 0.85;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    align-items: center;
    margin: 10px;
    margin-bottom: 2rem;

    h5 {
      min-height: 5rem;
  }
}
`;

export const AboutLeft = styled.div`
  flex: 1;
  justify-content: center;
  display: flex;
`;