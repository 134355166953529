import React, { useState } from "react";
import Dropdown from "../Dropdown/Dropdown";
import Header from "../Header/Header";
import {
  HeroWrapper,
  HeroLeft,
  HeroRight, Image,
} from "./HeroElements";
import { TypeAnimation } from 'react-type-animation';

function Hero() {
  const [isOpen, setIsOpen] = useState(false);
  const [showSubtitle, setShowSubtitle] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <main>

        <Dropdown isOpen={isOpen} toggle={toggle} />
        <Header toggle={toggle} />
        <div className="HeroContainer">

          <HeroWrapper>
            <HeroLeft>
              <TypeAnimation
                cursor={false}
                sequence={[
                  'Hi, I\'m Karan Chawla.',
                  () => setShowSubtitle(true)
                ]}
                speed={{ type: "keyStrokeDelayInMs", value: 100 }}
                wrapper="h1"
                repeat={0}
              />
              {showSubtitle &&
                <TypeAnimation
                  cursor={true}
                  sequence={[
                    2000,
                    `A Full-Stack Web Developer.`,
                    2000,
                    `A Database Adminstrator.`,
                    2000,
                    `A Computer Science Graduate.`,
                    2000,
                    `A problem solver.`,
                    2000,
                    `A team player.`,
                    2000,
                    `An innovative thinker.`,
                    2000,



                  ]}
                  speed={45}
                  deletionSpeed={60}
                  wrapper="h5"
                  repeat={Infinity}
                />
              }

            </HeroLeft>
            <HeroRight>
              <Image
                src="/logo2.svg"
                alt="myImage"
              />
              {/* <MovingBorder /> */}
            </HeroRight>
          </HeroWrapper>
        </div>


      </main>
      <img style={{ width: "100%", height: "auto" }}
        src="/wave2.svg"
        alt="myImage"
      />
    </>

  );
}

export default Hero;
