import React from 'react';

const FlowDex = () => {
  return (
    <>
      <div className='flowbox-container'>

        <div className="flowbox-top">
          <div className='imgHolder'>
            <img className='myImg' src="/img/exp/tcs.webp" alt="imageArea" />
          </div>
          <div className='contentHolder'>
            <div className='title'>Work Experience</div>
            <div>
              <div className='subtitle'>TCS <span>(Systems Engineer)</span></div>
              <div className='content'>Jun 2021 -- Present</div>
              <div className='content'>
                2.5+ years of industry experience in <a href="https://www.tcs.com/" target='_blank' rel='noreferrer'>TCS</a> as a <strong>Full Stack Web Developer</strong>.
              </div>
            </div>
            <div className='content'>
              Tata Consultancy Services (TCS) is a business solutions, consulting, and IT services company that has been partnering with the world's largest businesses for over 55 years. TCS is part of the Tata group, India's largest multinational business group. TCS has more than 614,000 consultants in 55 countries.
            </div>

          </div>
        </div>
        <div className="flowbox-left">
          <div className='imgHolder'>
            <img className='myImg' src="/img/exp/client.png" alt="imageArea" />
          </div>
          <div className='contentHolder'>
            <div className='title'>Clients in TCS</div>
            <div className='content'>
              Extensive experience in collaborating with international clients, fostering strong relationships and delivering exceptional service tailored to meet their unique needs and cultural expectations.
            </div>
            <div>
              <div className='subtitle'>1. Condé Nast <span>(2.5+ years)</span></div>
              <div className='content'>Implemented comprehensive web-based solutions for Condé Nast, encompassing full-stack development to enhance functionality, usability, and overall performance of their sites.</div>
            </div>
            <div>
              <div className='subtitle'>2. US News & College Advisor <span>(yet to start)</span></div>
              <div className='content'>Currently starting a new project with the above client</div>
            </div>

          </div>
        </div>
        <div className="flowbox-right">
          <div className='imgHolder'>
            <img className='myImg' src="/img/exp/frt.webp" alt="imageArea" />
          </div>
          <div className='contentHolder'>
            <div className='title'>Front End Solutions</div>
            <div className='content'>
              Delivered a spectrum of front-end solutions for diverse needs at Condé Nast, ranging from crafting intricate single React components to developing custom web pages for specific events in alignment with provided designs.
            </div>
            <div className='content'>
              Proficient in using: <strong>Valinna CSS(CSS3), React Js, Vanilla Javascript and Jquery.</strong>
            </div>
            <div className='content'>
              Adaptive to use: <strong>Bootstrap and Tailwind CSS.</strong>
            </div>

          </div>
        </div>
        <div className="flowbox-left">
          <div className='imgHolder'>
            <img className='myImg' src="/img/exp/back1.webp" alt="imageArea" />
          </div>
          <div className='contentHolder'>
            <div className='title'>Back End Solutions</div>
            <div className='content'>
              Delivered various back end solutions to clint like Intigration of Payment Gateway, Created Endpoints for publishing Google Web stories, Optimized pages for faster load time etc.
            </div>
            <div className='content'>
              Proficient in using: <strong>Node Js, Express Js and PHP </strong>
            </div>
            <div className='content'>
              Adaptive to use: <strong>Python Flask and Python Django</strong>
            </div>

          </div>
        </div>
        <div className="flowbox-right">
          <div className='imgHolder'>
            <img className='myImg' src="/img/exp/data.webp" alt="imageArea" />
          </div>
          <div className='contentHolder'>
            <div className='title'>Database Management</div>
            <div className='content'>
              Experienced in managing MYSQL and MongoDB of for client and provided custom endpoints for exporting the data in csv format for the clint to consume as per the requirements.
            </div>
            <div className='content'>
              Proficient in using: <strong>SQL, MYSQL, MongoDB, Mongoose and MongoDB Compass</strong>
            </div>
            <div className='content'>
              Adaptive to use: <strong>PostgreSQL</strong>
            </div>
          </div>
        </div>

      </div>
    </>


  )
}

export default FlowDex;