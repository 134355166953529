import React from 'react';
import { useRef } from 'react';

const AboutContent = () => {

    const img1Ref = useRef();
    const img2Ref = useRef();


    const animate1 = () => {
        const children = img1Ref.current.children;

        for(let i=0; i<children.length; i++) {
            children[i].classList.add('hover');
        }

    };

    const removeAnimate1 = () => {
        const children = img1Ref.current.children;

        for(let i=0; i<children.length; i++) {
            children[i].classList.remove('hover');
        }
    };

    const animate2 = () => {
        const children = img2Ref.current.children;

        for(let i=0; i<children.length; i++) {
            children[i].classList.add('hover');
        }

    };

    const removeAnimate2 = () => {
        const children = img2Ref.current.children;

        for(let i=0; i<children.length; i++) {
            children[i].classList.remove('hover');
        }
    };


    return (
        <>
            <div className="naboutContainer">

                <div className="nborder1"></div>
                <div className="nborder2"></div>
                <div className="nborder3"></div>
                <div className="nborder4"></div>

                {/* <div className="borderBoxOne"></div>
                <div className="borderBoxTwo"></div> */}

                <div className="naboutContentBox">
                    <div className="nimg1" ref={img1Ref}>
                        <div className="nimgHolder1"></div>
                        <div className="nimgHolder3"></div>
                        <div className="nimgHolder2" onMouseEnter={animate1} onMouseOut={removeAnimate1}>
                            <img style={{ height: "100%", width: "100%" }} src="/img/pics/003.jpeg" alt="Karan Chawla" />
                        </div>
                    </div>
                    <div className="nimg2" ref={img2Ref}>
                        <div className="nimgHolder1"></div>
                        <div className="nimgHolder3"></div>
                        <div className="nimgHolder2" onMouseEnter={animate2} onMouseOut={removeAnimate2}>
                            <img style={{ height: "100%", width: "100%" }} src="/img/pics/001.jpeg" alt="Karan Chawla" />
                        </div>
                    </div>

                    <div className="npara1">
                        <div className="subtitle">Professional Expertise and Passion for Learning</div>
                        <div className='content'>As a dedicated full stack developer, I specialize in utilizing the MERN stack to craft exceptional web applications. With a firm commitment to staying abreast of emerging technologies, I continually seek opportunities to expand my skill set and embrace novel innovations. My passion for learning ensures that I remain adaptable and capable of tackling diverse challenges in the ever-evolving landscape of web development.</div>
                        <div className="content">With over 2.5 years of hands-on experience in the industry, I have honed my expertise as a Full Stack Developer. Throughout my career, I have consistently demonstrated proficiency in crafting robust web solutions that seamlessly integrate frontend and backend functionalities, thereby enhancing user experiences and driving business success.</div>
                    </div>

                    <div className="npara2">
                        <div className="subtitle">Geographic Flexibility</div>
                        <div className='content'>Originally hailing from the historic city of Jabalpur, Madhya Pradesh, I am currently based in the vibrant metropolis of Pune, Maharashtra. However, my enthusiasm for professional growth knows no bounds, and I am readily open to opportunities that may require relocation across different regions of India.</div>
                        <div className="subtitle">Collaborative Spirit</div>
                        <div className='content'>A firm believer in the power of teamwork, I actively support and collaborate with my peers to achieve collective goals. I thrive in dynamic environments where collaboration fosters creativity and innovation, and I derive immense satisfaction from contributing to the success of my team members.</div>
                        <div className="subtitle">Academic Background</div>
                        <div className='content'>I am a proud alumnus of Vellore Institute of Technology, Bhopal, where I earned my Bachelor's degree in Computer Science and Technology with an outstanding CGPA of 8.84 in 2021. This rigorous academic journey equipped me with a strong foundation in computer science principles, empowering me to excel in the realm of software engineering.</div>
                    </div>
                    <div className="npara3">
                        <div className="subtitle">Career Aspirations</div>
                        <div className='content'>I am currently seeking a challenging career opportunity that offers both personal and professional growth prospects. I am eager to leverage my skills and experience in a role that not only allows me to contribute meaningfully to innovative projects but also provides avenues for continuous learning and advancement. </div>
                    </div>
                </div>


            </div>



        </>
    )
}

export default AboutContent