import React from "react";
import { FaLinkedin } from "react-icons/fa";
import styled from "@emotion/styled";
import { SiGmail } from "react-icons/si";
import { RiWhatsappFill } from "react-icons/ri";
import { motion } from "framer-motion";



const SocialContainer = styled.div`
  position: fixed;
  top: 48%;
  left: 1.5rem;
  transform: translateY(-50%);

  ul {
    display: block;
  }

  .item + .item {
    margin-top: 1rem;
  }

  a {
    font-size: 2.3rem;
    color: #2d234a;
    &:hover {
      color: #2d234a;
    }
  }

  @media screen and (max-width: 1000px) {
    margin-top: 2rem;
    position: relative;
    top: 0;
    left: 0;
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      list-style: none;
    }

    a {
      font-size: 2.5rem;
      color: #2d234a;
      &:hover {
        color: #2d234a;
      }
    }

    .item + .item {
      margin-top: 0;
      margin-left: 2rem;
    }
  }
`;
function FixSocialIcon() {

  return (
    <SocialContainer>
      <ul>
        <motion.div className="navButton"
          whileHover={{ scale: 1.4 }}
          whileTap={{ scale: 0.8 }}
        >
          <li className="item">
            <a
              href="https://www.linkedin.com/in/karan-chawla-815589199/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin />
            </a>
          </li>
        </motion.div>
        <motion.div className="navButton"
          whileHover={{ scale: 1.4 }}
          whileTap={{ scale: 0.8 }}
        >
          <li className="item">
            <a
              href="mailto:karan.chawla.04.11.98@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SiGmail />
            </a>
          </li>
        </motion.div>
        <motion.div className="navButton"
          whileHover={{ scale: 1.4 }}
          whileTap={{ scale: 0.8 }}
        >
          <li className="item">
            <a
              href="https://wa.me/+918839909620"
              target="_blank"
              rel="noopener noreferrer"
            >
              <RiWhatsappFill />
            </a>
          </li>
        </motion.div>
      </ul>
    </SocialContainer>
  );
}

export default FixSocialIcon;
