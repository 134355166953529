export const webSkills = [
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original.svg",
    name: "HTML",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original.svg",
    name: "CSS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg",
    name: "JavaScript",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/jquery/jquery-original.svg",
    name: "Jquery",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original.svg",
    name: "ReactJS",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original.svg",
    name: "NodeJs",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/php/php-original.svg",
    name: "PHP",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/wordpress/wordpress-original.svg",
    name: "Wordpress",
  }
];

export const apiSkills = [
  {
    img: "/img/icons/restapi.png",
    name: "REST",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/graphql/graphql-plain.svg",
    name: "Graphql",
  },
];

export const databaseSkills = [
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original.svg",
    name: "MYSQL",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original.svg",
    name: "Mongo DB",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongoose/mongoose-original.svg",
    name: "Mongoose",
  },
  
];

export const programmingSkills = [
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
    name: "Python",
  },
  {
    img: "/img/icons/CPP.png",
    name: "C++",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg",
    name: "Java",
  },
];

export const otherSkills = [
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/linux/linux-original.svg",
    name: "Linux",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/git/git-original.svg",
    name: "Git",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/github/github-original.svg",
    name: "Github",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/jenkins/jenkins-original.svg",
    name: "Jenkins",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/vim/vim-original.svg",
    name: "Vim",
  },
  {
    img: "https://raw.githubusercontent.com/devicons/devicon/master/icons/vscode/vscode-original.svg",
    name: "VS Code",
  },
];