import React from 'react';
import Hero from '../Components/Hero/Hero.jsx';
import About from '../Components/About/About.jsx';
import Skills from '../Components/Skills/Skills.jsx';
import Contact from '../Components/Contact/Contact.jsx';
import FixSocialIcon from '../Components/SocialIcon/FixSocialIcon.jsx';
import ScrollToTop from '../Components/SocialIcon/ScrollToTop.jsx';
import Footer from '../Components/Footer/Footer.jsx';
import Experience from '../Components/Experience/Experience.jsx';

import { useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';

const Home = () => {

  //Animate About
  const aboutref = useRef(null);
  const aboutisInView = useInView(aboutref, { once: true });
  const aboutmainControls = useAnimation();

  useEffect(() => {
    if (aboutisInView) {
      aboutmainControls.start('visible');

    }
  }, [aboutisInView, aboutmainControls]);

  //Animate experience
  const experienceref = useRef(null);
  const experienceisInView = useInView(experienceref, { once: true });
  const experiencemainControls = useAnimation();

  useEffect(() => {
    if (experienceisInView) {
      experiencemainControls.start('visible');

    }
  }, [experienceisInView, experiencemainControls]);

  //Animate Skills
  const skillsref = useRef(null);
  const skillsisInView = useInView(skillsref, { once: true });
  const skillsmainControls = useAnimation();

  useEffect(() => {
    if (skillsisInView) {
      skillsmainControls.start('visible');

    }
  }, [skillsisInView, skillsmainControls]);


  //Animate Contact
  const contactref = useRef(null);
  const contactisInView = useInView(contactref, { once: true });
  const contactmainControls = useAnimation();

  useEffect(() => {
    if (contactisInView) {
      contactmainControls.start('visible');

    }
  }, [contactisInView, contactmainControls]);

  return (
    <>

      <Hero />


      <motion.div ref={aboutref}
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={aboutmainControls}
        transition={{ duration: 0.6, delay: 0.1 }}
      >
        <About />

      </motion.div>

      <motion.div ref={experienceref}
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={experiencemainControls}
        transition={{ duration: 0.6, delay: 0.1 }}
      >
        <Experience />
      </motion.div>

      <motion.div ref={skillsref}
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={skillsmainControls}
        transition={{ duration: 0.6, delay: 0.1 }}
      >
        <Skills />
      </motion.div>

      <motion.div ref={contactref}
        variants={{
          hidden: { opacity: 0, y: 75 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={contactmainControls}
        transition={{ duration: 0.6, delay: 0.1 }}
      >
        <Contact />
      </motion.div>


      <FixSocialIcon />
      <Footer />
      <ScrollToTop />

    </>
  );
};

export default Home;