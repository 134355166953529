import React from "react";
import {
  ContactWrapper,
} from "./AboutElements";
// import AboutContentOld from "./AboutContentOld";
import AboutContent from "./AboutContent";


function About() {
  return (<>



    <ContactWrapper id="about">
      <div className="Container">
        <div className="SectionTitle">About Me</div>
        {/* <AboutContentOld /> */}
        <AboutContent />
      </div>
    </ContactWrapper>

    <ContactWrapper id="about">
      <div className="Container">


      </div>
    </ContactWrapper>
  </>
  );
}

export default About;
