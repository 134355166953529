import React from 'react';
// import Slider from './Slider';
import FlowDex from './FlowDex';
import FlowMob from './FlowMob';
import Swiper from './Swiper';

const Experience = () => {
    return (
        <>
            <div id="experience" className="Container">
                <div className="SectionTitle">Experience</div>


                {/* <div className="ExperienceCard">
                    <div className="ExperienceContainer">
                    <Slider></Slider>
                    </div>
                    
                </div> */}

                <FlowDex></FlowDex>
                <FlowMob></FlowMob>
                <Swiper></Swiper>




            </div>




        </>
    )
}

export default Experience