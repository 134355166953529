import React from 'react'

const Swiper = () => {
    return (
        <>
            <div className="SectionTitle hideMob">Brief Experience Slider</div>
            <div className="slidesHolder">


                {/* Old Swiper container*/}
                <swiper-container className="mySwiper" effect="cards" grab-cursor="true">
                    <swiper-slide><img src="/img/exp/0001.jpg" style={{ width: "100%", height: "100%" }} alt="something" /></swiper-slide>
                    <swiper-slide><img src="/img/exp/0002.jpg" style={{ width: "100%", height: "100%" }} alt="something" /></swiper-slide>
                    <swiper-slide><img src="/img/exp/0003.jpg" style={{ width: "100%", height: "100%" }} alt="something" /></swiper-slide>
                    <swiper-slide><img src="/img/exp/0004.jpg" style={{ width: "100%", height: "100%" }} alt="something" /></swiper-slide>
                    <swiper-slide><img src="/img/exp/0005.jpg" style={{ width: "100%", height: "100%" }} alt="something" /></swiper-slide>
                    <swiper-slide><img src="/img/exp/0006.jpg" style={{ width: "100%", height: "100%" }} alt="something" /></swiper-slide>
                    <swiper-slide><img src="/img/exp/0007.jpg" style={{ width: "100%", height: "100%" }} alt="something" /></swiper-slide>
                    <swiper-slide><img src="/img/exp/0008.jpg" style={{ width: "100%", height: "100%" }} alt="something" /></swiper-slide>
                </swiper-container>
            </div>
        </>
    )
}

export default Swiper